import React, { FC } from "react";
import { isMultiWordTag, replaceSpacesWithHyphens } from "@/utils/redirects";

export interface TagProps {
  tag: string;
  unstyled?: boolean;
}

export const Tag: FC<TagProps> = ({ tag, unstyled }) => (
  <a
    key={tag}
    className={`${
      !unstyled && "tag-label"
    } d-inline-block l-half-push-top text-1-25`}
    href={`/tags/${
      isMultiWordTag(tag)
        ? replaceSpacesWithHyphens(tag)
        : tag.trim().toLowerCase()
    }`}
  >
    {tag}
  </a>
);
